$(document).ready(function(){

  var size = window.innerWidth > 1000 ? 150 : 75,
      speed = 1e-2,
      start = Date.now();

  var sphere = {type: "Sphere"};

  var projection = d3.geo.orthographic()
      .scale(size / 2.2)
      .clipAngle(90)
      .translate([size / 2, size / 2])
      .precision(0.5);

  var heroSection = d3.select(".hero-section--content");

  var canvas = heroSection.insert('div', '.hero-section--title')
      .attr('class', 'spinning-globe')
      .append("canvas")
      .attr("width", size)
      .attr("height", size);

  var context = canvas.node().getContext("2d");

  var path = d3.geo.path()
      .projection(projection)
      .context(context);

  d3.json("/assets/js/world-110m.json", function(error, topo) {
    if (error) {
      d3.select('.spinning-globe').remove();
      heroSection.insert('svg', '.hero-section--title')
        .attr('class', 'icon icon-earth')
        .append('use')
        .attr('xlink:href', '#icon-earth');
      throw error;
    }

    var land = topojson.feature(topo, topo.objects.land);

    d3.timer(function() {
      projection.rotate([speed * (Date.now() - start), -15]);

      context.clearRect(0, 0, size, size);

      context.beginPath();
      path(land);
      context.fillStyle = "#fff";
      context.fill();

      context.beginPath();
      path(sphere);
      context.lineWidth = 1;
      context.strokeStyle = "#fff";
      context.stroke();
    });

  });

});
